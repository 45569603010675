<!--
 * @Author: your name
 * @Date: 2020-11-26 11:07:46
 * @LastEditTime: 2021-03-25 14:51:01
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \solax-design\src\views\projectList\inverterData\inverterDetail.vue
-->
<template>
    <div>
        <el-dialog
        :title="$t('project.Equipment-detail')"
        :visible="visibleInfo"
        width="40%"
        :before-close="handleClose">
        
        <div class="inverterDetail-main-box">
            <el-row  v-for="item,index in detailData" :key="index" style="margin-bottom:10px">
                <el-col :span="10">
                    {{item.paramKey}}
                    <!-- <div class="modal-box-flex">
                        <div>{{item.paramKey}}</div>
                        <div style="text-align:right">{{item.paramVal}}</div>
                    </div> -->
                </el-col>
                <el-col :span="14" style="text-align:right">{{item.paramVal}}</el-col>
            </el-row>
        </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {

    },
    props: {
        detailData:{
            type:Array,
            default:[]
        }
    },
    data() {
        return {

        };
    },
    computed: {
        visibleInfo(){
            return this.$store.state.sysStore.visibleDeviceInfo
        }
    },
    watch: {

    },
    created() {

    },
    mounted() {

    },
    methods: {
        handleClose(){
            this.$store.commit('setVisibleDeviceInfo',false)
        },
        returnA(){
            let data = this.detailData.tpvParamList || []
            let arr = data.map(item => item.iInvDCMax)
            return arr.join('/')
        }
    },
};
</script>

<style  lang="less" >
.inverterDetail-main-box{
    .modal-box-flex{
        display: flex;
        // justify-content: space-between;
        padding: 5px;
            div:nth-child(1){
            flex: 2
            }
            div:nth-child(2){
            flex: 1
            }
    }
}
</style>
